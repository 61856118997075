import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [{
        'data1':'KD-DUMMY-01',
        'data2':'RKAKL VERSI 1.0.1',
        'data3':'32000000',
        'data4':'Ya'
      },
      {
        'data1':'KD-DUMMY-02',
        'data2':'RKAKL VERSI 1.0.2',
        'data3':'42000000',
        'data4':'Ya',
      },
      {
        'data1':'KD-DUMMY-03',
        'data2':'RKAKL VERSI 1.0.3',
        'data3':'52000000',
        'data4':'Ya'
      },
      {
        'data1':'KD-DUMMY-04',
        'data2':'RKAKL VERSI 1.0.4',
        'data3':'36000000',
        'data4':'Ya'
      }],
      getData: [],
      setId: null,
      listBalai: [],
      listStatker: [],
      listEvent:[]
      

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
  

    this.G_GetRef('balai').then(data => {
      this.listBalai = data;
    });

    this.G_GetRef('userType').then(data => {
      this.list_usersTypes = data;
    });

    this.G_GetRef('userGroup').then(data => {
      this.list_userGroup = data;
    });

    this.G_GetRef('satker').then(data => {
      this.listStatker = data;
      if (this.getUser.userGroup == "satker") {
        this.$refs.satker.setValue(this.getUser.kdsatker)

      }else{
        this.listStatker.unshift({
            'text': 'Semua Satker',
            'value': 'all'
          })
      }

      this.getTable()
       
    });

    // this.G_GetAny('sakti/event').then(res => {
    //   let resData = res.data.content

    //   let listData = [];
    //   let arrSub = {};

    //   resData.forEach(v => {
    //       arrSub = {};

    //       arrSub['text'] = v.saktiEventNm;
    //       arrSub['value'] = v.saktiEventId;

    //       listData.push(arrSub);
    //   });

    //   this.listEvent = listData;

    // })

   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {

      let filter = [];

      if(this.$refs.satker.getValue()){

        if (this.$refs.satker.getValue() != 'all'){
          filter.push('kdsatker='+ this.$refs.satker.getValue());
        }
      }

      // if(this.$refs.event.getValue()){

      //   if (this.$refs.event.getValue() != 'all'){
      //     filter.push('saktiEventId='+ this.$refs.event.getValue());
      //   }
      // }

      filter.push("saktiEventId=" + this.$route.query.eventId);

      if (filter.length>0) {
        filter = '?'+filter.join("&");
      }


      this.loadingTb = true
      this.G_GetAny('rkakl/versi'+filter).then(res => {
  
        this.loadingTb = false
        this.tbData = res.data
      })
    },
    groupType(type,kdsatker){
      if (type == 'satker') {
        this.isBalai = false
        this.isSatker = true
        this.G_GetRef('satker').then(data => {
          this.listStatker = data;
        }) .finally(() => {
          this.$refs.kdsatker.setValue(kdsatker)
        });
      }else if(type == 'balai'){
        this.isBalai = true
        this.isSatker = false
      }
      else if(type == 'direktorat'){
        this.isBalai = false
        this.isSatker = true
        this.G_GetRef('satker','?type=direktorat').then(data => {
          this.listStatker = data;
        }).finally(() => {
          this.$refs.kdsatker.setValue(kdsatker)
        });
      }
      else if(type == 'ditjensda'){
        this.isBalai = false
        this.isSatker = false
      }
    },
    edit(id) {
      this.G_GetAny('user/' + id).then(res => {
        this.getData = res.data
        this.setId = res.data.userId
       

        this.groupType(this.getData.userGroup,res.data.kdsatker)
       

        this.$refs.modal.open("Edit Data User", 600)

        this.$nextTick(() => {

          this.$refs.usersTypes.setValue(res.data.usersTypes[0])
          this.$refs.userGroup.setValue(res.data.userGroup)
          this.$refs.kdbalai.setValue(res.data.kdbalai)
          // this.$refs.kdsatker.setValue(res.data.kdsatker)
        });
      })
    },
    add() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      this.setId = null
      this.getData = []
      this.$refs.modal.open("Buat Versi", 600)

    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);

      if (this.setId) {
        
      }else{
        formData.append('password','passwd098')
      }

     
      let arr = []
      arr.push(formData.get('usersTypes'))

      var object = {};
      formData.forEach((value, key) => object[key] = value);

      object['usersTypes'] = arr

      if (this.$refs.form.validate()) {
          this.$refs.modal.loading()

          if (this.setId) {
            this.G_PutAnyCustom('user', object).then(res => {
              if (res) {
                this.getTable();
                this.$snotify.success('Berhasil Mengubah Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }else{
            this.G_PostAnyCustom('user', object).then(res => {
              if (res) {
                this.getTable();
                this.$snotify.success('Berhasil Menyimpan Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }

         
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
    async reset(id) {
      let judul = "Reset Password"
      let subJudul = "Yakin Akan Reset Password User Ini?"

      if (await this.$refs.confirm.open(judul,subJudul) ) {
          let formData = new FormData();
          formData.append('userId',id)
          this.$refs.confirm.loading()
          this.G_PostAny('user/resetPassword', formData).then(res => {
  
              if (res) {
                  this.$refs.confirm.close()
                  this.$refs.modal.close()
                  this.getTable();
                  this.$snotify.success(res.message);
                  // this.$refs.notif.success(res.message,`Berhasil ${judul}`);
              }else{
                this.$snotify.warning(res.message);

                  // this.$refs.notif.warning(res.message,'Terjadi Kesalahan');
              }
          })
          .finally(() => {
              this.$refs.confirm.loadingEnd()
          }); 
      }

    },
    setSatker(id) {
      this.G_GetRef('satker', '?balaiId=' + id).then(data => {
        this.listStatker = data;
      });


    },
  }
}